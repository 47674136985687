import Footer from './Footer';
import Navigation from './Navigation';

function PrivacyPolicy() {
    return (
        <>
            <Navigation />
            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>Privacy Policy</h1>
                    <h4>Learn about our policies</h4>
                </div>

                <div className="container mt-4">
                    <div className="row">
                        <div className="col">
                            <p>Protecting your private information is our priority. This Statement of Privacy applies to <a href="https://pagado.io">pagado.io</a>, and pagado and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to pagado include <a href="https://pagado.io">pagado.io</a> and pagado. The pagado website is a Marketplace for customers to buy millix and millix related products, quickly and securely. site. By using the pagado website, you consent to the data practices described in this statement.</p>

                            <h3 className='mt-4'>Collection of your Personal Information</h3>
                            <p>In order to better provide you with products and services offered, pagado may collect personally identifiable information, such as your:</p>

                            <p>- E-mail Address</p>

                            <p>If you purchase pagado’s products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.</p>

                            <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>

                            <h3 className='mt-4'>Use of your Personal Information</h3>
                            <p>pagado collects and uses your personal information to operate and deliver the services you have requested.</p>

                            <p>pagado may also use your personally identifiable information to inform you of other products or services available from pagado and its affiliates.</p>

                            <h3 className='mt-4'>Sharing Information with Third Parties</h3>
                            <p>pagado does not sell, rent or lease its customer lists to third parties.</p>

                            <p>pagado may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to pagado, and they are required to maintain the confidentiality of your information.</p>

                            <p>pagado may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on pagado or the site; (b) protect and defend the rights or property of pagado; and/or (c) act under exigent circumstances to protect the personal safety of users of pagado, or the public.</p>

                            <h3 className='mt-4'>Automatically Collected Information</h3>
                            <p>Information about your computer hardware and software may be automatically collected by pagado. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the pagado website.</p>

                            <p>- Links</p>

                            <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>

                            <h3 className='mt-4'>Security of your Personal Information</h3>
                            <p>pagado secures your personal information from unauthorized access, use, or disclosure. pagado uses the following methods for this purpose:</p>

                            <p>- SSL Protocol</p>

                            <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>

                            <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>

                            <h3 className='mt-4'>Right to Deletion</h3>

                            <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</p>

                            <ul>
                                <li>Delete your personal information from our records; and</li>
                                <li>Direct any service providers to delete your personal information from their records.</li>
                            </ul>

                            <h3 className='mt-4'>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:</h3>

                            <ul>
                                <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;</li>

                                <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; Debug to identify and repair errors that impair existing intended functionality; Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</li>

                                <li>Comply with the California Electronic Communications Privacy Act; Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;</li>

                                <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</li>

                                <li>Comply with an existing legal obligation; or</li>

                                <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
                            </ul>

                            <h3 className='mt-4'>Children Under Thirteen</h3>
                            <p>pagado does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</p>

                            <h3 className='mt-4'>E-mail Communications</h3>
                            <p>From time to time, pagado may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.</p>

                            <p>If you would like to stop receiving marketing or promotional communications via email from pagado, you may opt out of such communications by Customers may unsubscribe from emails by “replying STOP” or “clicking on the UNSUBSCRIBE button.</p>

                            <h3 className='mt-4'>External Data Storage Sites</h3>
                            <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>

                            <h3 className='mt-4'>Changes to this Statement</h3>
                            <p>pagado reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>

                            <h3 className='mt-4'>Contact Information</h3>
                            <p>pagado welcomes your questions or comments regarding this Statement of Privacy. If you believe that pagado has not adhered to this Statement, please contact pagado at:</p>

                            <p>
                                pagado <br />
                                PO Box 883 <br />
                                Boynton Beach, Florida 33425
                            </p>

                            <p>
                                Email Address: <br />
                                info@pagado.io
                            </p>

                            <p>
                                Telephone number: <br />
                                5612212717
                            </p>

                            <p>Effective as of September 13, 2023</p>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>

    );
}

export default PrivacyPolicy;
