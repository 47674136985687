import Footer from "./Footer";
import Navigation from "./Navigation";

function Faqs() {
    return (
        <>
            <Navigation />
            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>FAQ's</h1>
                    <h4>Have any questions?</h4>
                </div>

                <div className="container mt-4">
                    <div className="row">
                        <div className="col">
                            <h2 className="mt-4 text-center">General Questions</h2>

                            <h3 className="mt-4">1. What is Millix?</h3>
                            <p><strong>Answer:</strong> Millix is a cryptocurrency designed for fast, secure, and low-cost transactions. Unlike traditional fiat currencies, it operates on a decentralized network, ensuring greater security and privacy.</p>

                            <h3 className="mt-4">2. How do I create an account?</h3>
                            <p><strong>Answer:</strong> To create an account, download our app from the App Store or Google Play, open the app, and follow the on-screen instructions to sign up using your email or phone number.</p>

                            <h3 className="mt-4">3. How do I add funds to my account?</h3>
                            <p><strong>Answer:</strong> You can add funds to your account by purchasing Millix through our partnered exchanges, or by transferring Millix from another wallet. Once you have Millix in your account, you can convert it to USD or other supported fiat currencies if needed.</p>

                            <h3 className="mt-4">4. How do I send Millix to another user?</h3>
                            <p><strong>Answer:</strong> To send Millix, open the app, select 'Send', enter the recipient's username or scan their QR code, enter the amount, and confirm the transaction. The recipient will receive the Millix instantly.</p>

                            <h3 className="mt-4">5. Can I convert Millix to USD or other fiat currencies?</h3>
                            <p><strong>Answer:</strong> Yes, you can convert Millix to USD or other supported fiat currencies directly within the app. Go to the 'Convert' section, select the amount of Millix you want to convert, choose your desired fiat currency, and confirm the conversion.</p>

                            <h3 className="mt-4">6. What are the fees for transactions?</h3>
                            <p><strong>Answer:</strong> We strive to keep our fees as low as possible. Transaction fees vary based on the amount and the type of transaction. For detailed information on our fee structure, please refer to our 'Fees' page within the app or on our website.</p>

                            <h3 className="mt-4">7. Is my money safe?</h3>
                            <p><strong>Answer:</strong> Yes, your funds are safe. We use advanced security measures including encryption, two-factor authentication (2FA), and secure blockchain technology to ensure the safety and privacy of your transactions.</p>

                            <h3 className="mt-4">8. What happens if I lose my phone?</h3>
                            <p><strong>Answer:</strong> If you lose your phone, you can access your account from another device by logging in with your email and password. We recommend enabling 2FA for added security. If you need further assistance, please contact our support team.</p>

                            <h3 className="mt-4">9. How do I contact customer support?</h3>
                            <p><strong>Answer:</strong> You can contact our customer support team through the 'Help' section in the app, by emailing support@pagado.io. Our team is available 24/7 to assist you.</p>

                            <h3 className="mt-4">10. Are there any limits on transactions?</h3>
                            <p><strong>Answer:</strong> Yes, there are limits on transactions to ensure security and compliance. These limits vary based on the type of transaction and your account verification level. Please refer to our 'Transaction Limits' page for more details.</p>

                            <h3 className="mt-4">11. How do I verify my account?</h3>
                            <p><strong>Answer:</strong> To verify your account, go to the 'Settings' section, select 'Verify Account', and follow the instructions to provide the necessary documents and information. Verification helps increase your transaction limits and provides additional security.</p>

                            <h3 className="mt-4">12. Can I use the app internationally?</h3>
                            <p><strong>Answer:</strong> Yes, you can use our app internationally to send and receive Millix. However, the availability of fiat currency conversions may vary by country. Please check our 'International Use' section for more details.</p>

                            <h3 className="mt-4">13. How do I earn rewards or cashback?</h3>
                            <p><strong>Answer:</strong> We offer various rewards and cashback programs for our users. To learn more about current promotions and how to earn rewards, visit the 'Rewards' section in the app or on our website.</p>

                            <h3 className="mt-4">14. What should I do if I suspect unauthorized activity on my account?</h3>
                            <p><strong>Answer:</strong> If you suspect unauthorized activity, immediately change your password, enable 2FA if you haven't already, and contact our support team. We will investigate and take the necessary steps to secure your account.</p>

                            <h3 className="mt-4">15. How can I stay updated on new features and updates?</h3>
                            <p><strong>Answer:</strong> Stay updated by following us on social media, subscribing to our newsletter, and regularly checking the 'Announcements' section in the app. We continuously work on improving our services and adding new features.</p>

                            <h2 className="mt-4 text-center">Merchant Services FAQs</h2>

                            <h3 className="mt-4">1. What is the Pagado plugin?</h3>
                            <p><strong>Answer:</strong> The Pagado plugin is a payment solution that allows merchants to accept Millix cryptocurrency as a form of payment on their online stores. It integrates seamlessly with popular e-commerce platforms, providing a secure and efficient payment method for customers.</p>

                            <h3 className="mt-4">2. How do I add the Pagado plugin to my online store?</h3>
                            <p><strong>Answer:</strong> To add the Pagado plugin to your online store, visit our website and download the plugin compatible with your e-commerce platform. Follow the installation guide provided, which includes steps to configure your payment settings and enable Millix payments.</p>

                            <h3 className="mt-4">3. What e-commerce platforms does the Pagado plugin support?</h3>
                            <p><strong>Answer:</strong> The Pagado plugin supports a wide range of e-commerce platforms, including WooCommerce, Shopify, Magento, and others. For a full list of supported platforms and detailed installation guides, please visit our 'Integration' page.</p>

                            <h3 className="mt-4">4. Are there any fees for using the Pagado plugin?</h3>
                            <p><strong>Answer:</strong> We offer competitive pricing with minimal transaction fees for using the Pagado plugin. The exact fees depend on the transaction volume and type. Please refer to our 'Fees' section on the website for detailed information.</p>

                            <h3 className="mt-4">5. How do I set up a POS solution for my physical store?</h3>
                            <p><strong>Answer:</strong> To set up a POS solution, you can choose from our range of compatible POS devices or integrate our payment system with your existing hardware. Our support team will assist you with the setup process, ensuring a smooth transition to accepting Millix payments in your physical store.</p>

                            <h3 className="mt-4">6. What are the benefits of accepting Millix payments for merchants?</h3>
                            <p><strong>Answer:</strong> Accepting Millix payments offers several benefits, including lower transaction fees, faster settlement times, increased security, and access to a growing community of cryptocurrency users. Additionally, it can help your business stand out by offering innovative payment options.</p>

                            <h3 className="mt-4">7. How do I convert Millix payments to fiat currency?</h3>
                            <p><strong>Answer:</strong> You can easily convert Millix payments to fiat currency directly through our merchant portal. Simply log in, select the amount of Millix you want to convert, choose your preferred fiat currency, and confirm the transaction. The funds will be transferred to your linked bank account.</p>

                            <h3 className="mt-4">8. Is customer support available for merchants?</h3>
                            <p><strong>Answer:</strong> Yes, we offer dedicated customer support for merchants. Our support team is available 24/7 to assist with any issues or questions related to the Pagado plugin, POS solutions, and other merchant services. You can contact us via email, phone, or through the merchant portal.</p>

                            <h3 className="mt-4">9. How do I view and manage transactions?</h3>
                            <p><strong>Answer:</strong> You can view and manage all your transactions through the merchant portal. The portal provides detailed transaction histories, real-time analytics, and reporting tools to help you track your sales and manage your finances effectively.</p>

                            <h3 className="mt-4">10. Are there any security measures in place for merchant transactions?</h3>
                            <p><strong>Answer:</strong> Yes, we implement advanced security measures to protect merchant transactions. These include encryption, fraud detection systems, and secure blockchain technology. Additionally, we provide tools to help merchants comply with regulatory requirements and protect customer data.</p>

                            <h3 className="mt-4">11. Can I offer refunds to my customers?</h3>
                            <p><strong>Answer:</strong> Yes, you can offer refunds to your customers directly through the merchant portal. Simply select the transaction you wish to refund, enter the refund amount, and confirm the process. The refunded amount will be deducted from your balance and returned to the customer.</p>

                            <h3 className="mt-4">12. How do I promote my business as a Millix-accepting merchant?</h3>
                            <p><strong>Answer:</strong> We provide marketing materials and support to help you promote your business as a Millix-accepting merchant. You can feature our logo on your website, use our promotional banners, and participate in our marketing campaigns to attract cryptocurrency users.</p>

                            <h3 className="mt-4">13. Are there any resources available to help me get started?</h3>
                            <p><strong>Answer:</strong> Yes, we offer a comprehensive range of resources, including installation guides, video tutorials, and best practice documents to help you get started with the Pagado plugin and POS solutions. Visit our 'Resources' section on the website for more information.</p>

                            <h3 className="mt-4">14. Can I integrate Millix payments with my existing accounting software?</h3>
                            <p><strong>Answer:</strong> Yes, our system supports integration with popular accounting software, allowing you to streamline your financial management. For specific integration guides and compatibility information, please refer to our 'Integration' page or contact our support team.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );
}

export default Faqs;
