import Footer from "./Footer";
import Navigation from "./Navigation";

export default function WordPressPlugin() {
    return (
        <>
            <Navigation />
            <div id="appCapsule" className="docs">
                <div className="section mt-4 text-center">
                    <h1>Pagado WordPress Plugin</h1>
                    <h4>Powered by WooCommerce</h4>
                </div>

                <div className="container mt-4">
                    <div className="row">
                        <div className="col">
                            <h2>Pagado WooCommerce Payment Method</h2>
                            <p>Please check PHP and WordPress versions compatibility <a href="https://make.wordpress.org/core/handbook/references/php-compatibility-and-wordpress-versions/">here</a> before you proceed.</p>

                            <p><strong>Visit our GitHub latest <a href="https://github.com/pagado-io/pagado-woocommerce/releases">releases</a> or download the latest version directly</strong> <a className="btn btn-icon btn-success btn-sm" href="/files/wp-plugin/pagado.zip">
                                <ion-icon size="small" name="cloud-download" />
                            </a></p>

                            <h3>Tested environment</h3>
                            <ul>
                                <li>PHP 8.3.14</li>
                                <li>WordPress 6.4.3</li>
                                <li>WooCommerce 8.6.1</li>
                                <li>Pagado 2.1.2</li>
                                <li>Dokan 3.10.1</li>
                            </ul>

                            <h3>Installation</h3>
                            <ul>
                                <li>Have WordPress installed and running.</li>
                                <li>Install and activate WooCommerce.</li>
                                <li>Install and activate Pagado Payment Processor plugin.</li>
                            </ul>

                            <h3>WooCommerce settings</h3>
                            <ol>
                                <li>Go to WooCommerce -> Settings -> General tab</li>
                                <li>From Currency options -> Currency, select Millix (MLX).</li>
                                <li>Use Save changes button to save the configuration. <br />
                                    <img src="/images/docs/select-currency.png" alt="Select Currency" />
                                </li>
                                <li>From WooCommerce -> Settings -> Payment tab, you should see Pagado Gateway is activated by default. If not make sure it's activated.</li>
                                <li>Click on Manage button. <br />
                                    <img src="/images/docs/manage-pagado.png" alt="Manage pagado" />
                                </li>
                                <li>Check Enable Pagado Payment Gateway.</li>
                                <li>Insert your pagado email and password into the respective fields.</li>
                                <li>Use Save changes button to save the configuration. <br />
                                    <img src="/images/docs/pagado-login.png" alt="Pagado Login" />
                                </li>
                            </ol>

                            <h3>Dokan multi-vendor settings</h3>
                            <p>Starting from Pagado version 2.0.0, Dokan multi-vendor support has been added. It allows seamless integration with Pagado payment gateway. While is has full support for Dokan, it also supports some additional features like instant automatic payment to vendor.</p>

                            <p><em>Note: Dokan features and documentation can be found <a href="https://dokan.co/docs/wordpress">here</a>.</em>
                            </p>

                            <h4>Get your Pagado API Direct key</h4>
                            <p>Pagado API Direct allows it's user to use supported features through it's API with API Direct key (logging into account is not necessary).</p>

                            <ol>
                                <li>Login to pagado.</li>
                                <li>Select API Direct from menu. <br />
                                    <img src="/images/docs/profile-api-direct-url.png" alt="API Direct URL" />
                                </li>
                                <li>Generate new key if you haven't already. You can Generate new one if you already have one. Use Delete if you want to disable API Direct access to your account. <br />
                                    <img src="/images/docs/api-direct-key-generation.png" alt="API Direct key generation" />
                                </li>
                                <li>You should get that key from here for use. You can always come back and get your existing key from here.</li>
                            </ol>

                            <h4>Admin setup</h4>
                            <ol>
                                <li>Login into WordPress as Admin.</li>
                                <li>From dashboard menu (on the left) go to WooCommerce -> Settings.</li>
                                <li>Select Payments tab.</li>
                                <li>Click on Pagado Gateway -> Manage button. <br />
                                    <img src="/images/docs/manage-pagado.png" alt="Manage Pagado" />
                                </li>
                                <li>Enter the API Direct key that we previously collected from Pagado API Direct page (Add email as well if you haven't already) and save changes. <br />
                                    <img src="/images/docs/wp-api-direct-key-setup.png" alt="API Direct key setup" />
                                </li>
                            </ol>

                            <h4>Vendor setup</h4>
                            <ol>
                                <li>Visit you vendor dashboard from navigation.</li>
                                <li>Go to Settings -> Payment.</li>
                                <li>Click on Add Payment Method and select Pagado. <br />
                                    <img src="/images/docs/dokan-add-payment-method.png" alt="Add Dokan payment method" />
                                </li>
                                <li>Add your Pagado E-mail and Update Settings. <br />
                                    <img src="/images/docs/dokan-add-pagado-payment-method.png" alt="Add Pagado payment method" />
                                </li>
                                <li>Now, when a customer purchases your product, you should get your share instantly on order completion.</li>
                                <li>In the admin dashboard admin can check vendor payments. To do so, visit admin dashboard Dokan -> Withdraw. You should see withdraws with their details and a transaction ID on success, in the withdraw note. <br />
                                    <img src="/images/docs/dokan-pagado-instant-withdraw.png" alt="Pagado instant withdraw" />
                                </li>
                            </ol>

                            <h3>Trouble shooting</h3>
                            <h4>Pagado payment option is not available</h4>
                            <img src="/images/docs/no-payment-option.png" alt="No payment option" />

                            <p>Starting with WooCommerce version 8.3, WooCommerce Cart and Checkout Blocks are the default for new installation. Unfortunately, Pagado Payment Gateway doesn't support block yet as it's in its very early stage of development. That's why it's not available in Block editor mode.</p>

                            <ol>To get Pagado Payment method working, you have to switch to Classic Editor mode. To do that
                                <li>Login to your WordPress site as admin.</li>
                                <li>Edit your checkout page by going to Pages -> Checkout or simply vising checkout page and click on the Edit from the top right on the screen.</li>
                                <li>When in the edit checkout page click any content from the left side. <br />
                                    <img src="/images/docs/checkout-block.png" alt="Checkout Block" />
                                </li>
                                <li>You'll see a Pagado Gateway warning.</li>
                                <li>Click on the Switch to classic checkout button. <br />
                                    <img src="/images/docs/block-warning.png" alt="Block warning" />
                                </li>
                                <li>You should see a warning. Click on Switch.</li>
                                <li>Finally Update the page.</li>
                            </ol>
                            <em>Note: You can simply switch to classic editor as well.</em>
                            <p>Now you should be able to see Pagado as a payment option on the checkout page.</p>
                            <img src="/images/docs/pagado-payment-method.png" alt="Pagado payment method" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
